import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-meal-ingredients"
}
const _hoisted_2 = { class: "c-meal-name-container" }
const _hoisted_3 = { for: "name" }
const _hoisted_4 = {
  key: 0,
  class: "p-field p-fluid"
}
const _hoisted_5 = {
  key: 0,
  class: "error-bites"
}
const _hoisted_6 = {
  key: 1,
  class: "name-description"
}
const _hoisted_7 = ["aria-label"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "total-row" }
const _hoisted_14 = ["colspan"]
const _hoisted_15 = { class: "total-calories-cell" }
const _hoisted_16 = { class: "total-bites-cell" }
const _hoisted_17 = { class: "total-bites-cell" }
const _hoisted_18 = { class: "total-bites-cell" }
const _hoisted_19 = {
  key: 0,
  class: "error-bites"
}

import { Food, MenuMealDetailDto, MenuMealDto } from '@/models/NutritionalPlan';
import { Intolerances } from '@/models/PatientInfo';
import { nutritionalPlanService } from '@/services/NutritionalPlanService';
import { ServiceError } from '@/services/util/ServiceError';
import { computed, onMounted, onUpdated, ref } from 'vue';
import DropdownWithFoodSearch from './DropdownWithFoodSearch.vue';
import HealzInput from '@/components/healz/ui/HealzInput.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'RationsTable',
  props: {
  editMode: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: false,
  },
  foods: {
    type: Array as () => MenuMealDetailDto[],
    required: true,
  },
  mealMenu: {
    type: Object as () => MenuMealDto,
    required: true,
  },
  foodIntolerances: {
    type: Array as () => Intolerances[],
    required: true,
  },
},
  emits: [
  'on-new-food',
  'on-delete-food',
  'on-quantiy-change',
  'on-food-change',
  'on-name-change',
  'on-create-alternative',
],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const props = __props;

const menuMealName = ref(props.name);

const foodsOptions = ref<Food[]>([]);

const foodsOptionsDropdown = computed<Food[]>(() => {
  return foodsOptions.value.filter((food) => !hasIntolerances(food));
});

const hasIntolerances = (food: Food): boolean => {
  if (!props.foodIntolerances || !food.foodIntolerances) {
    return false;
  }

  return props.foodIntolerances.some(
    (intolerance) => intolerance !== Intolerances.NO && food.foodIntolerances.includes(intolerance),
  );
};

const rationsOptionsDropdown = computed(() => {
  const array = [];
  for (let i = 0.5; i <= 20; i += 0.5) {
    array.push({ label: i % 1 === 0 ? i.toString() : i.toFixed(1), value: i });
  }
  return array;
});

onMounted(async () => {
  await fetchFoods();
});

onUpdated(async () => {
  menuMealName.value = props.name;
});

const fetchFoods = async () => {
  const result = await nutritionalPlanService.findAllFoods();

  if (!(result instanceof ServiceError)) {
    foodsOptions.value = result;
    foodsOptions.value = foodsOptions.value.sort((a, b) => {
      const aLabel = a.name;
      const bLabel = b.name;
      return aLabel.localeCompare(bLabel);
    });
  }
};

const totalBitesProteins = computed(() => {
  return props.foods.reduce((total: number, row: any) => {
    return total + parseFloat(row.food.proteinBites) * row.bites || 0;
  }, 0);
});

const totalBitesFats = computed(() => {
  return props.foods.reduce((total: number, row: any) => {
    return total + parseFloat(row.food.fatBites) * row.bites || 0;
  }, 0);
});

const totalBitesCarbohydrates = computed(() => {
  return props.foods.reduce((total: number, row: any) => {
    return total + parseFloat(row.food.carbohydratesBites) * row.bites || 0;
  }, 0);
});

const totalFoodCalories = (menuMealDetail: MenuMealDetailDto) => {
  const { food, bites } = menuMealDetail;
  return (food.carbohydratesBites * 4 + food.proteinBites * 4 + food.fatBites * 9) * 10 * bites;
};

const totalFoodsCalories = computed(() => {
  let total = 0;
  props.foods.forEach((food) => {
    const { carbohydratesBites, proteinBites, fatBites } = food.food;
    total += (carbohydratesBites * 4 + proteinBites * 4 + fatBites * 9) * 10 * food.bites;
  });
  return total;
});

const errorInName = computed(() => {
  return menuMealName.value !== undefined && menuMealName.value !== null && menuMealName.value.length > 46;
});

const errorInTotalBites = computed(() => {
  return (
    totalBitesProteins.value !== props.mealMenu.proteinBites ||
    totalBitesFats.value !== props.mealMenu.fatBites ||
    totalBitesCarbohydrates.value !== props.mealMenu.carbohydratesBites
  );
});

const onQuantityChange = (newValue: any, index: number) => {
  emits('on-quantiy-change', newValue, index);
};

const onDeleteFood = (indexFood: number) => {
  emits('on-delete-food', indexFood);
};

const onNewFoodClick = () => {
  emits('on-new-food');
};

const onFoodChange = (newFood: any, index: number) => {
  const food = foodsOptions.value.find((food) => food.id === newFood.id);

  if (food) {
    emits('on-food-change', food, index);
  }
};

const onNameChange = () => {
  emits('on-name-change', menuMealName.value);
};

const onCreateAlternative = () => {
  emits('on-create-alternative', props.mealMenu.id);
};

return (_ctx: any,_cache: any) => {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!

  return (__props.foods)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('nutrition.menu.mealName')), 1),
          (__props.editMode)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(HealzInput, {
                  id: "name",
                  modelValue: menuMealName.value,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((menuMealName).value = $event)),
                    onNameChange
                  ],
                  modelModifiers: { trim: true },
                  editable: __props.editMode,
                  class: _normalizeClass({ 'p-invalid': errorInName.value }),
                  placeholder: _ctx.$t('nutrition.menu.mealNamePlaceHolder')
                }, null, 8, ["modelValue", "editable", "class", "placeholder"]),
                (errorInName.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _cache[2] || (_cache[2] = _createElementVNode("i", { class: "icon-error_outline" }, null, -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.menu.error-name', { count: menuMealName.value?.length })), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(__props.mealMenu?.name?.trim() ? __props.mealMenu?.name : '-'), 1))
        ]),
        _createElementVNode("table", {
          "aria-label": _ctx.$t('nutrition.menu.ration')
        }, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              (__props.editMode)
                ? (_openBlock(), _createElementBlock("th", _hoisted_8, _toDisplayString(_ctx.$t('nutrition.menu.ration')), 1))
                : _createCommentVNode("", true),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.food')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.quantity')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.foodDistribution.kcal')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.proteins')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.fats')), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t('nutrition.menu.carbs')), 1),
              _cache[3] || (_cache[3] = _createElementVNode("th", null, null, -1))
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.foods, (food, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                (__props.editMode)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_9, [
                      _createVNode(_component_Dropdown, {
                        id: food.bites,
                        modelValue: food.bites,
                        "onUpdate:modelValue": ($event: any) => ((food.bites) = $event),
                        options: rationsOptionsDropdown.value,
                        "option-label": "label",
                        "option-value": "value",
                        class: _normalizeClass({
                'no-editable': !__props.editMode,
              }),
                        onChange: ($event: any) => (onQuantityChange($event, index))
                      }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "options", "class", "onChange"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("td", null, [
                  (__props.editMode)
                    ? (_openBlock(), _createBlock(DropdownWithFoodSearch, {
                        key: 0,
                        food: food,
                        "foods-options": foodsOptionsDropdown.value,
                        onOnFoodChange: ($event: any) => (onFoodChange($event, index))
                      }, null, 8, ["food", "foods-options", "onOnFoodChange"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(food.food.name), 1)
                      ], 64))
                ]),
                _createElementVNode("td", null, [
                  _createTextVNode(_toDisplayString(food.food.quantity * food.bites) + " ", 1),
                  (food.food.units)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t(`nutrition.menu.units.${food.food.units}`)), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", null, _toDisplayString(totalFoodCalories(food)), 1),
                _createElementVNode("td", null, _toDisplayString(food.food.proteinBites * food.bites), 1),
                _createElementVNode("td", null, _toDisplayString(food.food.fatBites * food.bites), 1),
                _createElementVNode("td", null, _toDisplayString(food.food.carbohydratesBites * food.bites), 1),
                _createElementVNode("td", null, [
                  (__props.editMode)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "p-button-only-icon",
                        onClick: ($event: any) => (onDeleteFood(index))
                      }, _cache[4] || (_cache[4] = [
                        _createElementVNode("i", { class: "icon-trash_full" }, null, -1)
                      ]), 8, _hoisted_11))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128)),
            (__props.editMode)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
                  _cache[6] || (_cache[6] = _createElementVNode("td", null, null, -1)),
                  _cache[7] || (_cache[7] = _createElementVNode("td", null, null, -1)),
                  _cache[8] || (_cache[8] = _createElementVNode("td", null, null, -1)),
                  _cache[9] || (_cache[9] = _createElementVNode("td", null, null, -1)),
                  _cache[10] || (_cache[10] = _createElementVNode("td", null, null, -1)),
                  _cache[11] || (_cache[11] = _createElementVNode("td", null, null, -1)),
                  _cache[12] || (_cache[12] = _createElementVNode("td", null, null, -1)),
                  _createElementVNode("td", null, [
                    _createElementVNode("button", {
                      class: "p-button-only-icon",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (onNewFoodClick()))
                    }, _cache[5] || (_cache[5] = [
                      _createElementVNode("i", { class: "icon-add-square" }, null, -1)
                    ]))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("tfoot", null, [
            _createElementVNode("tr", _hoisted_13, [
              _createElementVNode("td", {
                class: "total-cell",
                colspan: __props.editMode ? '2' : '1'
              }, _toDisplayString(_ctx.$t('nutrition.menu.total-bites')), 9, _hoisted_14),
              _cache[13] || (_cache[13] = _createElementVNode("td", null, null, -1)),
              _createElementVNode("td", _hoisted_15, _toDisplayString(totalFoodsCalories.value), 1),
              _createElementVNode("td", _hoisted_16, _toDisplayString(totalBitesProteins.value), 1),
              _createElementVNode("td", _hoisted_17, _toDisplayString(totalBitesFats.value), 1),
              _createElementVNode("td", _hoisted_18, _toDisplayString(totalBitesCarbohydrates.value), 1),
              _cache[14] || (_cache[14] = _createElementVNode("td", null, null, -1))
            ])
          ])
        ], 8, _hoisted_7),
        (errorInTotalBites.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
              _cache[15] || (_cache[15] = _createElementVNode("i", { class: "icon-error_outline" }, null, -1)),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('nutrition.menu.error-total-bites')), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_Button, {
          class: "p-button-secondary p-button-medium",
          label: _ctx.$t('nutrition.menu.button-create-alternative'),
          onClick: onCreateAlternative
        }, null, 8, ["label"])
      ]))
    : _createCommentVNode("", true)
}
}

})